/* eslint-env browser, jquery */
/* global Routing,swal,toastr,feather */
// VERSES
let verseSearchInputStart = $('#verse-search-start')
let verseSearchInputEnd = $('#verse-search-end')
let verseBookInput = $('#verse-book')
let verseConfirmContainer = $('.verse-confirm-container')
let objectId = verseConfirmContainer.attr('data-id')
let objectType = verseConfirmContainer.attr('data-type')
let objectRoute = verseConfirmContainer.attr('data-route')
let versesTable = $('.verses-table')
let verseData = null

const startAt = $('#discount_startAt').val()

// search verse
$('.search-verse').on('click', function (event) {
  event.preventDefault()
  let searchStrBook = verseBookInput.val()
  let searchStrStart = verseSearchInputStart.val()
  let searchStrEnd = verseSearchInputEnd.val()

  if (searchStrStart.length) {
    $.ajax({
      type: 'GET',
      url: Routing.generate('verse_search'),
      data: {
        'data-type': objectType,
        'data-id': objectId,
        'verse-search-start': searchStrStart,
        'verse-search-end': searchStrEnd,
        'verse-search-book': searchStrBook
      }
    })
      .done(function (response) {
        if (response.verses) {
          verseData = response.verses
          let verseConfirmHtml = ''
          verseData.forEach(function (e, i) {
            verseConfirmHtml += '<p class="mb-1"><strong>Verse text:</strong></p><p>' + e.verseText +
              '</p>'
          })
          verseConfirmHtml += '<a href="#" class="' + getBtnClasses('add-verse btn-mobile') + '">Add verses</a>'
          verseConfirmContainer.html(verseConfirmHtml)
          verseSearchInputStart.val('')
          verseSearchInputEnd.val('')
        } else if (response.message) {
          verseConfirmContainer.html('<div class="alert alert-info" role="alert">' + response.message + '</div>')
        } else {
          verseConfirmContainer.html('<div class="alert alert-warning" role="alert">Verse not found</div>')
        }
      })
      .fail(function (response) {
        alert('Something went wrong. Please try again later')
      })
  }
})
// add verse
verseConfirmContainer.on('click', '.add-verse', function (event) {
  event.preventDefault()
  if (verseData) {
    $.ajax({
      type: 'POST',
      url: Routing.generate(objectRoute + '_add_verse', {'id': objectId}),
      data: {'verseIds': verseData.map(function (e) {
        return e.id
      })}
    })
      .done(function (response) {
        if (versesTable.length) {
          verseConfirmContainer.html('')
          let verseRow = ''
          verseData.forEach(function (e, i) {
            verseRow += '<tr>'
            verseRow += '<td>' + e.book + '</td>'
            verseRow += '<td>' + e.chapter + '</td>'
            verseRow += '<td>' + e.startVerse + '</td>'
            verseRow += '<td>' + e.verseText + '</td>'
            verseRow += '<td><a href="#" class="' + getBtnClasses('delete-verse') + '" data-id="' + e.id + '">Delete</a></td>'
            verseRow += '</tr>'
          })

          versesTable.find('tbody').append(verseRow)
        } else {
          location.reload()
        }
      })
      .fail(function (response) {
        alert('Something went wrong. Please try again later')
      })
  }
})
// delete verse
versesTable.on('click', '.delete-verse', function (event) {
  event.preventDefault()
  let deleteObj = $(this)
  let verseId = deleteObj.attr('data-id')
  swal({
    title: 'Are you sure?',
    text: 'Once deleted, you will not be able to recover this item!',
    icon: 'warning',
    buttons: true,
    dangerMode: true
  })
    .then((willDelete) => {
      if (willDelete) {
        swal('Successfully deleted!', {
          icon: 'success'
        })
          .then((value) => {
            if (value === true) {
              $.ajax({
                type: 'POST',
                url: Routing.generate(objectRoute + '_delete_verse', {'id': objectId}),
                data: {'verseId': verseId}
              })
                .done(function (response) {
                  deleteObj.parents('tr').remove()
                })
                .fail(function (response) {
                  alert('Something went wrong. Please try again later')
                })
            }
          })
      }
    })
})

$('#back-btn').on('click', function () {
  parent.history.back()
  return false
})

function getBtnClasses (customClasses) {
  return customClasses + ' btn btn-sm btn-outline-secondary'
}

function getValidateRules (formElem) {
  let validators = $.parseJSON(formElem.attr('data-validators'))
  let elemRules = {}

  for (let key in validators) {
    elemRules[key] = validators[key]
  }

  return elemRules
}

// eslint-disable-next-line
function notificationMessage (params) {
  if (params.type === 'success') {
    toastr.success(params.messageBody)
  }
  if (params.type === 'warning' || params.type === 'danger') {
    toastr.warning(params.messageBody)
  }
}

function getMonthName (monthNumber) {
  let months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December']
  return months[monthNumber - 1]
}

function hideDateFieldInCourse (element) {
  if (element.is(':checked')) {
    $('[data-course-date]').closest('div').hide()
  } else {
    $('[data-course-date]').closest('div').show()
  }
}

$(document).ready(function () {
  $('[data-course-date]').change(function (e) {
    let current = $(e.currentTarget)
    if (current.val()) {
      let dateStr = current.val().split('-')
      $('[data-course-title]').val(getMonthName(dateStr[1]) + ' ' + dateStr[2])
    }
  })
  let firstCourseCheckbox = $('[data-course-first-checkbox]')
  hideDateFieldInCourse(firstCourseCheckbox)
  firstCourseCheckbox.change(function (e) {
    hideDateFieldInCourse($(e.currentTarget))
  })
  let formToVal = $('.front-validation')

  if (formToVal.length) {
    formToVal.after('<div class="d-none" id="images-container"></div>')

    let imgsContainer = $('#images-container')

    // custom jquery validate methods
    $.validator.addMethod('minImageWidth', function (value, element, minWidth) {
      return !value || (($(element).data('imageWidth') || 0) >= minWidth)
    }, function (minWidth, element) {
      let imageWidth = $(element).data('imageWidth')
      return (imageWidth)
        ? ("Your image's width must be greater than " + minWidth + 'px')
        : 'Selected file is not an image.'
    })

    $.validator.addMethod('minImageHeight', function (value, element, minHeight) {
      return !value || (($(element).data('imageHeight') || 0) >= minHeight)
    }, function (minHeight, element) {
      let imageHeight = $(element).data('imageHeight')
      return (imageHeight)
        ? ("Your image's height must be greater than " + minHeight + 'px')
        : 'Selected file is not an image.'
    })

    let validator = formToVal.validate({
      wrapper: 'ul',
      errorElement: 'li',
      errorPlacement: function (error, element) {
        error.addClass('invalid-feedback invalid-custom list-unstyled mb-0')
        element.after(error)
      },
      submitHandler: function (form) {
        form.submit()
      }
    })

    $('.jquery-validate', formToVal).each(function (i) {
      let $input = $('input', this)

      // add validation rules
      $input.rules('add', getValidateRules($(this)))

      if ($(this).hasClass('validate-dimension')) {
        let $photoInput = $('input:file', this)
        let imgId = 'image-' + i
        imgsContainer.append("<div id='" + imgId + "'></div>")
        let $imgContainer = $('#' + imgId)

        $photoInput.change(function () {
          $photoInput.removeData('imageWidth')
          $imgContainer.hide().empty()

          let file = this.files[0]

          if (file.type.match(/image\/.*/)) {
            let reader = new FileReader()

            reader.onload = function () {
              let $img = $('<img />').attr({ src: reader.result })

              $img.on('load', function () {
                $imgContainer.append(this).show()

                $photoInput.data('imageWidth', this.naturalWidth)
                $photoInput.data('imageHeight', this.naturalHeight)

                validator.element($photoInput)
              })
            }

            reader.readAsDataURL(file)
          } else {
            validator.element($photoInput)
          }
        })
      }
    })
  }

  // Configure bootstrap-select
  $('.multi-selector').selectpicker({
    style: 'btn-prime',
    size: 10
  })

  // Configure toastr
  toastr.options = {
    'closeButton': true,
    'debug': false,
    'newestOnTop': false,
    'progressBar': true,
    'positionClass': 'toast-top-right',
    'preventDuplicates': false,
    'onclick': null,
    'showDuration': '300',
    'hideDuration': '3000',
    'timeOut': '2000',
    'extendedTimeOut': '1000',
    'showEasing': 'swing',
    'hideEasing': 'linear',
    'showMethod': 'fadeIn',
    'hideMethod': 'fadeOut'
  }

  // Disable date selectors by default
  setDateSelectorsAvailabilityState()

  // Toggle activity for date selectors after change checkboxes state
  $('.checkbox-limited').change(function () {
    setDateSelectorsAvailabilityState()
  })

  const currentDate = new Date()

  $('.date-selector').datepicker({
    uiLibrary: 'bootstrap4',
    format: 'dd.mm.yyyy',
    startDate: (startAt && startAt.length) ? startAt : currentDate
  })
})

function setDateSelectorsAvailabilityState () {
  if ($('input.checkbox-limited').is(':checked')) {
    $('.date-selector').removeAttr('disabled')
  } else {
    $('.date-selector').attr('disabled', 'disabled')
  }
}

feather.replace()
