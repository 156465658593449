/* eslint-env browser, jquery */
(function () {
  let emotionForm = $('[data-emotion-form]')
  let emotionCourseField = emotionForm.find('[data-emotion-meditation-course]')
  let emotionMeditationField = emotionForm.find('[data-emotion-meditation]')
  function disableMeditation () {
    if (emotionCourseField.val()) {
      emotionMeditationField.attr('disabled', false)
    } else {
      emotionMeditationField.attr('disabled', true)
    }
  }
  $(document).ready(function () {
    disableMeditation()
    emotionCourseField.change(function () {
      emotionMeditationField.html(emotionMeditationField.find('option').get(0))
      $.ajax({
        url: emotionForm.attr('action'),
        type: emotionForm.attr('method'),
        data: emotionForm.serialize()
      }).done(function (response) {
        emotionMeditationField.html($(response).find('[data-emotion-meditation]').html())
        disableMeditation()
      }).fail(function () {
        alert('Something went wrong. Please try again later')
      })
    })
  })
})()
